import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import styles from "./NetworkList.module.scss"
import IconGithub from "../../images/github.svg"
import IconCodepen from "../../images/codepen.svg"
import IconDribbble from "../../images/dribbble.svg"
import IconTumblr from "../../images/tumblr.svg"
import IconEmail from "../../images/email.svg"

export default props => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        network {
                            github {
                                url
                                name
                                icon
                            }
                            codepen {
                                url
                                name
                                icon
                            }
                            dribbble {
                                url
                                name
                                icon
                            }
                            tumblr {
                                url
                                name
                                icon
                            }
                            email {
                                url
                                name
                                icon
                            }
                        }
                    }
                }
            }
        `
    )

    const networks = data.site.siteMetadata.network
    const iconChooser = function(iconName) {
        switch (iconName) {
            case "github":
                return <IconGithub className={styles.social_links_list__icon} />
            case "codepen":
                return (
                    <IconCodepen className={styles.social_links_list__icon} />
                )
            case "dribbble":
                return (
                    <IconDribbble className={styles.social_links_list__icon} />
                )
            case "tumblr":
                return <IconTumblr className={styles.social_links_list__icon} />
            case "email":
                return <IconEmail className={styles.social_links_list__icon} />
            default:
                return null
        }
    }
    const DetailListItem = obj =>
        Object.keys(obj).map(key => {
            return (
                <li
                    className={styles.social_links_list__item}
                    key={obj[key].icon}
                >
                    <a
                        href={obj[key].url}
                        className={styles.social_links_list__link}
                    >
                        {iconChooser(obj[key].icon)}

                        <span className={styles.social_links_list__label}>
                            {obj[key].name}
                        </span>
                    </a>
                </li>
            )
        })

    return (
        <ul
            className={[
                `${styles.social_links_list}
                ${props.narrow ? styles.social_links_list____narrow : null}`,
            ]}
        >
            {DetailListItem(networks)}
        </ul>
    )
}
