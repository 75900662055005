import React from "react"
import styles from "./Hamburger.module.scss"
import MenuContext from "../../context/menuContext"

const Hamburger = () => (
    <MenuContext.Consumer>
        {menu => (
            <button
                className={`${styles.hamburger__button}`}
                id="hamburger"
                onClick={menu.toggleMenu}
            >
                <span>Menu</span>

                <svg
                    className={styles.hamburger__icon}
                    width="18"
                    height="22"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect x="0" y="0" width="100%" height="4px" />
                    <rect x="0" y="9" width="100%" height="4px" />
                    <rect x="0" y="18" width="100%" height="4px" />
                </svg>
            </button>
        )}
    </MenuContext.Consumer>
)

export default Hamburger
