import React from "react"
import styles from "./Lightswitch.module.scss"
import ThemeContext from "../../context/themeContext"

const Lightswitch = () => (
    <ThemeContext.Consumer>
        {theme => (
            <button
                className={`${styles.lightswitch} ${
                    theme.themeIsDark
                        ? styles.lightswitch____dark
                        : styles.lightswitch____light
                }`}
                onClick={theme.changeTheme}
                aria-label="Toggle Between Light and Dark Themes"
            >
                <svg
                    className={styles.lightswitch__icon}
                    width="18"
                    height="26"
                    viewBox="0 0 18 26"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        className={styles.lightswitch__icon_track}
                        d="M18 17L18 9C18 4.02944 13.9706 6.10673e-07 9 3.93402e-07C4.02943 1.76132e-07 -4.39524e-06 4.02944 -1.22419e-05 9L-1.25916e-05 17C-5.17947e-06 21.9706 4.02943 26 9 26C13.9706 26 18 21.9706 18 17Z"
                    />
                    <circle
                        className={styles.lightswitch__icon_handle}
                        cx="9"
                        cy="9"
                        r="9"
                    />
                </svg>
            </button>
        )}
    </ThemeContext.Consumer>
)

export default Lightswitch
