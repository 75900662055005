import React from "react"
import { ThemeProvider } from "./src/context/themeContext"
import { MenuProvider } from "./src/context/menuContext"
import Layout from "./src/layout/Layout"

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>
}

export const wrapRootElement = ({ element }) => {
    return (
        <ThemeProvider>
            <MenuProvider>{element}</MenuProvider>
        </ThemeProvider>
    )
}
