import React, { useState } from "react"

const ThemeProvider = props => {
    let [themeIsDark, setThemeState] = useState(false)

    return (
        <ThemeContext.Provider
            value={{
                themeIsDark,
                changeTheme: () => setThemeState(!themeIsDark),
            }}
        >
            {props.children}
        </ThemeContext.Provider>
    )
}

const ThemeContext = React.createContext()

export default ThemeContext

export { ThemeProvider }
