import React from "react"
import styles from "./Footer.module.scss"
import NetworkList from "../NetworkList/NetworkList"

export default () => {
    const currentYear = new Date().getFullYear()

    return (
        <footer className={styles.footer}>
            <div className={styles.footer__inner}>
                <NetworkList />
            </div>
            <div className={styles.footer__copyright_notice}>
                Copyright Glynn Smith &copy; {currentYear}, a front-end designer
                and developer based in Bristol - Built with{" "}
                <a
                    href="https://www.gatsbyjs.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Gatsby
                </a>
                ,{" "}
                <a
                    href="https://reactjs.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    React
                </a>
                , and{" "}
                <a
                    href="https://sass-lang.com/documentation/syntax"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    SCSS
                </a>
            </div>
        </footer>
    )
}
