import React from "react"
import ThemeContext from "../context/themeContext"
import Masthead from "../components/Masthead/Masthead"
import Footer from "../components/Footer/Footer"

const Layout = ({ children, location }) => (
    <ThemeContext.Consumer>
        {theme => (
            <div
                id="theme"
                className={`theme ${
                    theme.themeIsDark ? "theme____dark" : "theme____light"
                }`}
            >
                <section className="layout__full">
                    <Masthead />
                    <main key={location.pathname} className="layout__main">
                        {children}
                    </main>
                    <Footer />
                </section>
            </div>
        )}
    </ThemeContext.Consumer>
)

export default Layout
