import React, { useState, useRef, useEffect } from "react"
import {
    disableBodyScroll,
    enableBodyScroll,
    clearAllBodyScrollLocks,
} from "body-scroll-lock"
import { scrollbar, scrollbarWidth } from "../helpers/scrollbar"

const MenuProvider = props => {
    let [menuIsOpen, setMenuState] = useState(false)
    let bodyTag = () => (typeof document !== `undefined` ? document.body : null)
    let $body = useRef(bodyTag)

    useEffect(() => {
        if (menuIsOpen && scrollbar(document.documentElement)) {
            disableBodyScroll($body.current)
            document.getElementById("theme").style.paddingRight = scrollbarWidth
            document.documentElement.style.setProperty(
                "--scrollbar-width",
                scrollbarWidth
            )
        } else {
            enableBodyScroll($body.current)
            document.getElementById("theme").style.paddingRight = "0px"
            document.documentElement.style.setProperty(
                "--scrollbar-width",
                "0px"
            )
        }

        return () => {
            clearAllBodyScrollLocks()
        }
    }, [menuIsOpen])

    return (
        <MenuContext.Provider
            value={{
                menuIsOpen,
                toggleMenu: () => setMenuState(!menuIsOpen),
                closeMenu: () => setMenuState((menuIsOpen = false)),
            }}
        >
            {props.children}
        </MenuContext.Provider>
    )
}

const MenuContext = React.createContext()

export default MenuContext

export { MenuProvider }
