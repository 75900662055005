import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Icon from "../../images/logo.svg"
import styles from "./Masthead.module.scss"
import Hamburger from "../Hamburger/Hamburger"
import Lightswitch from "../Lightswitch/Lightswitch"
import MenuContext from "../../context/menuContext"

const ListLink = props => (
    <MenuContext.Consumer>
        {menu => (
            <li className={styles.masthead__navigation_item} key={props.to}>
                <Link
                    className={styles.masthead__navigation_link}
                    to={props.to}
                    onClick={menu.closeMenu}
                >
                    {props.children}
                </Link>
            </li>
        )}
    </MenuContext.Consumer>
)

const Masthead = () => {
    const data = useStaticQuery(
        graphql`
            query {
                site {
                    siteMetadata {
                        title
                        role
                    }
                }
            }
        `
    )

    return (
        <MenuContext.Consumer>
            {menu => (
                <>
                    <div
                        className={`${styles.masthead__overlay} ${
                            menu.menuIsOpen ? styles.open : ""
                        }`}
                    />
                    <header
                        className={styles.masthead}
                        id="masthead"
                        role="banner"
                    >
                        <div className={styles.masthead__inner}>
                            <Link
                                className={styles.masthead__logo_link}
                                to="/"
                                onClick={() => menu.closeMenu()}
                            >
                                <div className={styles.masthead__logo_wrapper}>
                                    <Icon className={styles.masthead__logo} />
                                    <div className={styles.masthead__logo_text}>
                                        <span
                                            className={
                                                styles.masthead__logo_text____title
                                            }
                                        >
                                            {data.site.siteMetadata.title}
                                        </span>
                                        <span
                                            className={
                                                styles.masthead__logo_text____role
                                            }
                                        >
                                            {" "}
                                            - {data.site.siteMetadata.role}
                                        </span>
                                    </div>
                                </div>
                            </Link>
                            <nav
                                className={styles.masthead__navigation}
                                role="navigation"
                            >
                                <span
                                    className={`${
                                        styles.masthead__navigation_offset
                                    } ${menu.menuIsOpen ? styles.open : ""}`}
                                >
                                    <ul
                                        className={`${
                                            styles.masthead__navigation_menu
                                        } ${
                                            menu.menuIsOpen ? styles.open : ""
                                        }`}
                                        id="navigation__menu"
                                    >
                                        <ListLink to="/">Home</ListLink>
                                        <ListLink to="/about/">About</ListLink>
                                        <ListLink to="/work/">Work</ListLink>
                                        <ListLink to="/weblog/">
                                            Weblog
                                        </ListLink>
                                    </ul>
                                </span>
                                <span className={styles.masthead__buttons}>
                                    <Hamburger />
                                    <Lightswitch />
                                </span>
                            </nav>
                        </div>
                    </header>
                </>
            )}
        </MenuContext.Consumer>
    )
}

export default Masthead
